import { createLogger } from '@drivably-apps/component-lib';
import {
  HasuraUrl,
  IsDevEnvironment,
  LogEnabled,
  LogEnv,
  LoggerAppSourceName,
  LogKey,
} from './config';

export const LogType = {
  Error: 'error',
  Info: 'info',
};

export const logger = createLogger({
  apiKey: LogKey,
  appSource: LoggerAppSourceName,
  isConsoleEnabled: IsDevEnvironment,
  isServiceEnabled: LogEnabled,
  env: LogEnv,
});

export async function sendLogRest({
  message = '',
  error = {},
  logType = LogType.Error,
}) {
  const LogParams = {};
  const { request, response, config } = error;
  LogParams.api = {
    req: {
      body: request?.data,
      method: config?.method,
      url: (config?.baseURL || '') + (config?.url || ''),
      responseType: request?.responseType,
    },
    res: {
      data: response?.data,
      error: JSON.stringify(config?.message || {}),
      status: response?.status,
      statusText: response?.statusText,
      url: response?.responseURL,
    },
  };

  if (logType === LogType.Error) {
    logger.error(message, LogParams);
  }
}

const excludeError = "Invalid 'To' Phone Number:";

export const sendLogGraphQL = ({
  message,
  error = {},
  logType = LogType.Error,
  variables,
}) => {
  if (!error?.message?.includes(excludeError)) {
    const errorString = JSON.stringify(error);
    const body = variables;
    const params = {
      api: {
        req: {
          baseUrl: HasuraUrl,
          body,
        },
        res: {
          error: errorString,
        },
      },
    };

    if (logType === LogType.Error) {
      logger.error(message, params);
    }
  }
};
