export const RedirectPathKey = 'path';

export const Severity = {
  Low: 'low',
  Medium: 'medium',
  High: 'high',
};

export const SeverityLabel = {
  Low: 'Minor',
  Medium: 'Moderate',
  High: 'Major',
};

export const pageTypes = {
  Leads: 'leads',
  Appointment: 'appointment',
  Appraisals: 'appraisals',
};

export const appraisalSeverityTypes = {
  Info: 'info',
  Warning: 'warning',
  Success: 'success',
};

export const disclosureAnswerTypes = {
  Severity: 'severity',
  Types: 'types',
  Choice: 'choice',
  Default: 'default',
};

// Colors from component-lib
export const VehicleDamageDefaultSeverityColor = {
  [Severity.Low]: '#FFEFBF',
  [Severity.Medium]: '#FEF2EC',
  [Severity.High]: '#FFDAD6',
};

export const disclosureAnswerKeys = {
  // body_damage: { key: 'body_damage', type: disclosureAnswerTypes.Severity },
  rust_damage: { key: 'rust_damage', type: disclosureAnswerTypes.Severity },
  // engine_issues: { key: 'engine_issues', type: disclosureAnswerTypes.Types },
  // mechanical_issues: {
  //   key: 'mechanical_issue',
  //   type: disclosureAnswerTypes.Types,
  // },
  option_equipment: {
    key: 'option_equipment',
    type: disclosureAnswerTypes.Choice,
    issue: null,
    alert: false,
  },
  body_damage: {
    key: 'body_damage',
    type: disclosureAnswerTypes.Severity,
    alert: false,
  },
  engine_issues: {
    key: 'engine_issues',
    type: disclosureAnswerTypes.Types,
    alert: false,
  },
  interior_issues: {
    key: 'interior_issue',
    type: disclosureAnswerTypes.Types,
    alert: false,
  },
  mechanical_issues: {
    key: 'mechanical_issue',
    type: disclosureAnswerTypes.Types,
    alert: false,
  },
  warning_lights: {
    key: 'warning_lights',
    type: disclosureAnswerTypes.Types,
    alert: false,
  },
  modifications: {
    key: 'modifications',
    type: disclosureAnswerTypes.Types,
    alert: false,
  },
  tire_issues: {
    key: 'tire_issues',
    type: disclosureAnswerTypes.Types,
    alert: false,
  },
  tire_tread: {
    key: 'tire_tread',
    type: disclosureAnswerTypes.Choice,
    issue: 'poor',
    alert: false,
  },
  glass_damage: {
    key: 'glass_damage',
    type: disclosureAnswerTypes.Default,
    alert: false,
  },
};

export const bodyDamageValues = ['Minor', 'Moderate', 'Major'];

export const appraisalType = {
  advocateAppraisal: {
    title: 'Customer Advocate Appraisal',
    severity: appraisalSeverityTypes.Info,
  },
  dealerAppraisal: {
    title: 'Dealer Appraisal',
    severity: appraisalSeverityTypes.Warning,
  },
  customerAppraisal: {
    title: 'Consumer Appraisal',
    severity: appraisalSeverityTypes.Success,
  },
};

export const AppraisalSourceBy = {
  Appraisal: {
    key: 'appraisal_offer',
    status: 'appraisal',
  },
  Advocate: {
    key: 'reviewed_offer',
    status: 'appointment',
  },
  Consumer: {
    key: 'online_offer',
    status: 'lead',
  },
};

export const Locales = {
  us: 'en-US',
};

export const DateFormating = {
  appointment: 'YYYY-MM-DD | hh:mm A',
};

export const DateFormats = {
  mm_dd_yy: 'MM/DD/YY',
  mmmm_d_yyyy: 'MMMM D, YYYY',
  ddd_mmmm_d_yyyy: 'dddd, MMMM D, YYYY',
  h_mm_A: 'h:mm A',
  yyyy_mm_dd: 'YYYY/MM/DD',
  MDYY_h_mm_A: 'M/D/YY, h:mm A',
};

export const AcvDealerId = '199';
export const AcvDealerIdGuaranteePrice = '357';

export const MAX_CHARACTERS = 250;

export const Engine = {
  Acv: 'acv',
};

export const Mode = {
  MakeModel: 'make_model',
};

export const AppraisalSource = {
  AppraisalOffer: 'appraisal_offer',
  ManualLead: 'manual_lead',
  ReviewedOffer: 'reviewed_offer',
  StandaloneDisclosure: 'standalone_disclosure',
};

export const OfferPriceCalculation = {
  OfferLow: {
    percentSting: '34%',
    percentNumber: 12,
  },
  OfferMiddle: {
    percentSting: '64%',
    percentNumber: 4,
  },
  OfferHigh: {
    percentSting: '2%',
    percentNumber: 6,
  },
};

export const colorCollection = {
  black: { text: 'Black', value: 'black', hex: '#000' },
  blue: { text: 'Blue', value: 'blue', hex: '#00f' },
  brown: { text: 'Brown', value: 'brown', hex: '#964b00' },
  gray: { text: 'Gray', value: 'gray', hex: '#808080' },
  green: { text: 'Green', value: 'green', hex: '#0f0' },
  orange: { text: 'Orange', value: 'orange', hex: '#f60' },
  purple: { text: 'Purple', value: 'purple', hex: '#60f' },
  red: { text: 'Red', value: 'red', hex: '#f00' },
  silver: { text: 'Silver', value: 'silver', hex: '#c0c0c0' },
  tan: { text: 'Tan', value: 'tan', hex: '#d2b48c' },
  white: { text: 'White', value: 'white', hex: '#fff' },
  yellow: { text: 'Yellow', value: 'yellow', hex: '#ff0' },
};

export const colorList = {
  blue: '#00558C',
  lightBlue: '#4D91BD',
};

export const colorCollectionList = Object.values(colorCollection);

export const photoStatus = {
  WithPhotos: 'has_photos',
  WithoutPhotos: 'no_photos',
};

export const defaultFilters = {
  photos: true,
  store: true,
  year: true,
  make: true,
  model: true,
  style: true,
  appraiserId: false,
  source: true,
};

export const leadsTabTypes = {
  Active: 'active',
  Appointments: 'appointments',
  Archive: 'archive',
};

export const statusTypes = {
  ClosedWon: 'closed_won',
  ClosedLost: 'closed_lost',
};

export const appointmentDays = {
  DaysToAdd: 1,
  DaysToSubtract: -1,
};

export const NOTIFICATION_POLL_INTERVAL = 60000;
export const TOAST_DURATION = 5000;

export const amazonLogo =
  'https://storage.googleapis.com/drv-img/amazon_logo.svg';
export const carAnimation =
  'https://storage.googleapis.com/drv-img/car-animation.gif';
export const loudSpeaker =
  'https://storage.googleapis.com/drv-img/loudspeaker.gif';
