import { gql } from '@apollo/client';

export const AppraisalCreateGql = gql`
  mutation AppraisalCreate(
    $accident: String
    $answers_drivably: jsonb
    $appraiser_id: Int
    $bb_adjusted_whole_avg: Int
    $bb_adjusted_whole_clean: Int
    $bb_adjusted_whole_rough: Int
    $bb_adjusted_whole_xclean: Int
    $color: String
    $condition: String
    $disclosure_answers: jsonb
    $disclosure_id: Int
    $disclosure_questions: jsonb
    $disclosure_response: jsonb
    $engine: String
    $mode: String
    $odometer: Int
    $offer_accepted: Boolean
    $offer_acv: Int
    $offer_bb: Int
    $offer_displayed: Boolean
    $offer_floor: Int
    $offer_range_acv: String
    $offer_range_bb: String
    $offer_top: Int
    $offer: Int
    $dealer_guaranteed_price: Int
    $sourced_by: String
    $store_id: Int
    $trade_in: String
    $vehicle_id: Int
    $zip_code: String
  ) {
    insert_appraisal_one(
      object: {
        appraiser_id: $appraiser_id
        bb_adjusted_whole_avg: $bb_adjusted_whole_avg
        bb_adjusted_whole_clean: $bb_adjusted_whole_clean
        bb_adjusted_whole_rough: $bb_adjusted_whole_rough
        bb_adjusted_whole_xclean: $bb_adjusted_whole_xclean
        disclosures: {
          data: {
            accident: $accident
            acv_disclosure_id: $disclosure_id
            answers_drivably: $answers_drivably
            color: $color
            condition: $condition
            disclosure_answers: $disclosure_answers
            disclosure_questions: $disclosure_questions
            disclosure_response: $disclosure_response
            odometer: $odometer
            store_id: $store_id
            trade_in: $trade_in
            vehicle_id: $vehicle_id
            zip_code: $zip_code
          }
        }
        engine: $engine
        mode: $mode
        offer_accepted: $offer_accepted
        offer_acv: $offer_acv
        offer_bb: $offer_bb
        offer_displayed: $offer_displayed
        offer_floor: $offer_floor
        offer_range_acv: $offer_range_acv
        offer_range_bb: $offer_range_bb
        offer_top: $offer_top
        offer: $offer
        dealer_guaranteed_price: $dealer_guaranteed_price
        sourced_by: $sourced_by
        vehicle_id: $vehicle_id
      }
    ) {
      id
      disclosure_id
    }
  }
`;

export const AppraisalFindByUuidGql = gql`
  query AppraisalFindByUuid($uuid: uuid!) {
    appraisal(where: { uuid: { _eq: $uuid } }) {
      id
      uuid
      vehicle {
        id
        km
        style
        trim
        uuid
        vin
        source_platform
        uuid
        store {
          id
          calibrationID
        }
        appraisals {
          id
          disclosures {
            id
            acv_disclosure_id
          }
        }
        inspections {
          id
          monk_inspection_status
          monk_inspection_id
        }
      }
    }
  }
`;

export const UpdateGuaranteeStatusGql = gql`
  mutation UpdateGuaranteeStatus($id: Int!, $accepted_date: timestamptz!) {
    update_appraisal_by_pk(
      pk_columns: { id: $id }
      _set: { dealer_guaranteed_accepted_date: $accepted_date }
    ) {
      id
      dealer_guaranteed_accepted_date
    }
  }
`;
