const errorList = {
  unhandledError: 'Something went wrong.',
  requestFailedUnknown: 'HTTP request failed unexpectedly.',
  urlInvalid: 'HTTP request URL is invalid.',
  smsNotSent: 'Failed to send SMS for Photo Capture',
  httpRequestFailed: 'Http request failed',
  graphqlRequestFailed: 'Graphql request failed',
  failedToLoadDamageSection: 'Failed to load the damage report section',
  failedToUpdateRecon: 'Failure to store/update the recon values',
  failedToLoadVehiclePhotos: 'Failed to load vehicle photos',
  failedToLoadVehicleInformation:
    'Failed to load vehicle information including pricing, customer or store information',
  failedToGetMonkPdf: 'Failed to retrieve the Monk PDF report',
  failedToGetConsumerOffer: 'Failed to generate the consumer offer report',
  failedImageCorsHandler: 'Failed to fetch image.',
  failedToFetchRetailPrice: 'Failed to fetch retail price.',
  invalidMobileNumner: 'Invalid phone number.',
};

export const errors = Object.entries(errorList).reduce(
  (obj, [errorCode, errorMessage]) => ({
    ...obj,
    [errorCode]: {
      code: errorCode,
      message: errorMessage,
    },
  }),
  {}
);
