import { gql } from '@apollo/client';

export const UserLoginGql = gql`
  query UserLogin($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      email
      id
      token
    }
  }
`;

export const UserFindByIdGql = gql`
  query UserFindById($id: Int!) {
    users(where: { id: { _eq: $id } }) {
      id
      name
      email
      managed_stores: portal_roles(
        where: { store: { managed: { _eq: true } } }
      ) {
        store {
          id
          name
          managed
          enable_consumer_guaranteed_price
          enable_dealer_guaranteed_price
          vehicles(
            where: {
              moved_to_appointment_at: { _is_null: false }
              _not: { appraisals: { appraiser_id: { _is_null: false } } }
            }
            limit: 1
          ) {
            moved_to_appointment_at
            appraisals {
              appraiser_id
            }
          }
        }
      }
      portal_roles {
        store {
          id
          name
          vehicles_aggregate(
            where: { appraisals: { appraiser_id: { _is_null: false } } }
          ) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`;
