import { useLazyQuery as useLazyQueryAC } from '@apollo/client';

import { useAlert } from './useAlert';
import { locale } from '@portal/locale';

/**
 * Integrate error handling and alert notification on apollo client useLazyQuery
 * hook
 */
export const useLazyQuery = (
  query,
  { successText, errorText, onError, onCompleted, ...props } = {}
) => {
  const { setSuccessAlert, setErrorAlert } = useAlert();

  const handleComplete = (data) => {
    if (data) {
      // Some queries denote action therefore allow success prompts if intended
      if (successText) {
        setSuccessAlert(successText);
      }

      onCompleted?.(data);
    }
  };

  const handleError = (error) => {
    if (
      error?.message
        ?.toLocaleLowerCase()
        ?.includes(locale.validatePhoneNumberMessage)
    ) {
      setErrorAlert(locale.vehicleDetailEditPhoneValidation);
    } else {
      setErrorAlert(errorText);
    }
    onError?.(error);
  };

  const [func, { loading: isLoading, ...data }] = useLazyQueryAC(query, {
    ...props,
    onCompleted: handleComplete,
    onError: handleError,
  });

  const funcWithError = async (params) => {
    try {
      const res = await func(params);
      return res;
    } catch (e) {
      handleError(e);
    }
  };

  return [funcWithError, { isLoading, ...data }];
};
