export const generateYears = (minimum) => {
  const currentYear = new Date().getFullYear();

  const years = Array.from(
    Array(currentYear - minimum + 1),
    (_, i) => `${currentYear - i}`
  );

  return years.map((year) => {
    return { text: year, value: year };
  });
};

export const YearOptions = generateYears(1990);

export const initialMakeModelOptions = {
  years: [...YearOptions],
  makes: [],
  models: [],
  trims: [],
  styles: [],
};

export const AddLeadFields = {
  Email: 'email',
  FirstName: 'firstName',
  IsStore: 'isStore',
  Phone: 'phone',
  LastName: 'lastName',
  Mileage: 'mileage',
  Make: 'make',
  LicensePlateId: 'licensePlateId',
  Model: 'model',
  Trim: 'trim',
  Year: 'year',
  ZipCode: 'zipCode',
  Plate: 'plate',
  State: 'state',
  Store: 'store',
  Vin: 'vin',
  MakeModel: 'make_model',
  VehicleDetails: 'vehicleDetails',
  SquishVin: 'squishVin',
  Category: 'category',
  Style: 'style',
};

export const LeadCategoryOptions = [
  { text: 'Plate', value: AddLeadFields.Plate },
  { text: 'Vin', value: AddLeadFields.Vin },
  { text: 'Make & Model', value: AddLeadFields.MakeModel },
];

export const InitialFormData = {
  category: AddLeadFields.Plate,
  email: '',
  firstName: '',
  isStore: false,
  lastName: '',
  licensePlateId: '',
  make: '',
  mileage: '',
  model: '',
  phone: '',
  state: '',
  store: '',
  trim: '',
  vin: '',
  year: '',
  zipCode: '',
};

export const Country = {
  US: 'U',
  CA: 'C',
};

export const formatList = (list = []) => {
  return Object.keys(list)?.map((name) => ({
    text: name,
    value: name,
  }));
};

export const GenerateMakeURL = (selectedYear) =>
  `?year=${selectedYear}&country=${Country.US}&drilldeep=true`;

export const GenerateUsedVehicleURL = (vin, year, make) =>
  `/UsedVehicle/${!!vin ? `VIN/${vin}` : `${year}/${make}`}`;

export const USStates = [
  { text: 'AL', value: 'AL' },
  { text: 'AK', value: 'AK' },
  { text: 'AZ', value: 'AZ' },
  { text: 'AR', value: 'AR' },
  { text: 'CA', value: 'CA' },
  { text: 'CO', value: 'CO' },
  { text: 'CT', value: 'CT' },
  { text: 'DE', value: 'DE' },
  { text: 'DC', value: 'DC' },
  { text: 'FL', value: 'FL' },
  { text: 'GA', value: 'GA' },
  { text: 'HI', value: 'HI' },
  { text: 'ID', value: 'ID' },
  { text: 'IL', value: 'IL' },
  { text: 'IN', value: 'IN' },
  { text: 'IA', value: 'IA' },
  { text: 'KS', value: 'KS' },
  { text: 'KY', value: 'KY' },
  { text: 'LA', value: 'LA' },
  { text: 'ME', value: 'ME' },
  { text: 'MD', value: 'MD' },
  { text: 'MA', value: 'MA' },
  { text: 'MI', value: 'MI' },
  { text: 'MN', value: 'MN' },
  { text: 'MS', value: 'MS' },
  { text: 'MO', value: 'MO' },
  { text: 'MT', value: 'MT' },
  { text: 'NE', value: 'NE' },
  { text: 'NV', value: 'NV' },
  { text: 'NH', value: 'NH' },
  { text: 'NJ', value: 'NJ' },
  { text: 'NM', value: 'NM' },
  { text: 'NY', value: 'NY' },
  { text: 'NC', value: 'NC' },
  { text: 'ND', value: 'ND' },
  { text: 'OH', value: 'OH' },
  { text: 'OK', value: 'OK' },
  { text: 'OR', value: 'OR' },
  { text: 'PA', value: 'PA' },
  { text: 'RI', value: 'RI' },
  { text: 'SC', value: 'SC' },
  { text: 'SD', value: 'SD' },
  { text: 'TN', value: 'TN' },
  { text: 'TX', value: 'TX' },
  { text: 'UT', value: 'UT' },
  { text: 'VT', value: 'VT' },
  { text: 'VA', value: 'VA' },
  { text: 'WA', value: 'WA' },
  { text: 'WV', value: 'WV' },
  { text: 'WI', value: 'WI' },
  { text: 'WY', value: 'WY' },
];

export const CountryCode = {
  US: 'US',
  CA: 'CA',
};

/** Which tool the vehicle was created from */
export const SourcePlatform = {
  Amazon: 'lux',
  GuaranteedPrice: 'guaranteed_price',
  Harness: 'harness',
  Manual: 'manual',
  QuickQuote: 'quick_quote',
  Widget: 'widget',
};

/** These stores allow new disclosure form to be rendered */
export const EnabledPlatforms = [
  SourcePlatform.Amazon,
  SourcePlatform.GuaranteedPrice,
];
export const StandardOfferDifference = 300;
export const EngineName = 'acv';
export const SourcedBy = 'manual_lead';
export const VehicleSourcePlatform = 'manual';
export const VehicleTitle = 'Manual Lead';
export const KMMultiplier = 1.609;
export const VinLength = 17;
export const FormattedPhoneLength = 14;
export const RegexVIN = /^[A-HJ-NPR-Z0-9]{12}[0-9]{5}$/;
export const RegexPlate = /^[^+_=/*?@#$%&()'"|â„;:{}.,`~<>}{][^\\]{1,20}$/;
export const RegexEmail =
  /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
export const RegexZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
export const RegexName = /^[A-Za-z][A-Za-z0-9 ]{1,50}$/;
export const ErrorMessage = (errors, touched, field) =>
  touched[field] &&
  errors[field] && <div className="error">{errors[field]}</div>;

/* UsedVehicle endpoint accept squish vin without the last digit. */
export const normalizedSquishVin = (squishVin) =>
  squishVin && squishVin.slice(0, -1);

// List of store IDs for which photo capture is disabled.
// Using store IDs as constants temporarily, they will be migrated during attribute consolidation.
export const disabledPhotoCaptureStoreIds = new Set([
  978, 732, 727, 875, 956, 472, 970, 942, 941, 887, 844, 689, 616, 940, 923,
  860, 855, 826, 801, 800, 711, 612, 590, 588, 503,
]);

export const formatOnlyNumbers = (value) =>
  `${value}`?.trim()?.replace(/[^\d]/g, '');

/* API VinToPlate error decode text */
export const vinToPlateErrorCode = {
  BE1001: 'Please enter a valid License Plate Number.',
  BE1002: 'Please choose the State where the vehicle is registered.',
  BE1003:
    'No Vehicles Found for the given plate or state. Please verify your entry.',
  BE1004: 'Sorry! We seem to have run into a technical issue. Please retry.',
  BE1005:
    'Sorry! We are unable to process this request currently. Please retry.',
  BE1006:
    'Sorry! We are unable to process this request currently. Please retry.',
  BE1007: 'Sorry we seem to have run into a technical issue. Please retry.',
  BE1008: 'RapidAPI: No Vehicles Found For The Given Plate Or State.',
  BE1009: 'RapidAPI: Request Creation Failed.',
  BE1010: 'RapidAPI: Request Failed.',
  BE1011: 'RapidAPI: Response Read Failed.',
  BE1012: 'RapidAPI: Response Unmarshalling Failed.',
  BE1013: 'RapidAPI: Invalid Plate or No VIN Found.',
  BE1014: 'RapidAPI: Invalid State or No VIN Found',
};
