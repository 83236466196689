import {
  split,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
import { sendLogGraphQL } from '@portal/lib';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { HasuraUrl, HasuraWebsocketUrl } from './config';
export const createClient = (token) => {
  const authHeader = token ? { Authorization: `Bearer ${token}` } : {};
  const authLink = setContext((_, { headers }) => ({
    headers: { ...headers, ...authHeader },
  }));
  const httpLink = new HttpLink({ uri: HasuraUrl });
  const wsLink = new WebSocketLink({
    uri: HasuraWebsocketUrl,
    options: {
      reconnect: true,
      connectionParams: {
        headers: authHeader,
      },
    },
  });
  // Error handling link
  const errorLink = onError((err) => {
    const { graphQLErrors, networkError, operation, response } = err;
    if (graphQLErrors) {
      console.log('### graphQLErrors', graphQLErrors, err);
      graphQLErrors.forEach(({ message, extensions, locations, path }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Code: ${extensions?.code}, Path: ${path}, Operation: ${operation.operationName}`
        );
        // Optionally, you can handle specific error codes
        if (extensions?.code) {
          console.error(`Validation error: ${message}`, locations);
          // Perform additional actions, like showing a notification to the user
        }
      });
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
    sendLogGraphQL({
      error: graphQLErrors,
      message: 'GraphQL Client Log' + response?.errors?.[0]?.message,
      variables: operation?.variables,
    });
    console.log('sendLogGraphQL', response?.errors?.[0]?.message);
  });
  // Custom request interceptor (e.g., logging)
  const requestInterceptor = new ApolloLink((operation, forward) => {
    return forward(operation);
  });
  // Split link to route subscription to WebSocket and others to HTTP
  const splitLink = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink
  );
  // Combine all links
  const link = ApolloLink.from([
    errorLink, // Handles errors globally
    requestInterceptor, // Logs requests
    authLink, // Adds authentication headers
    splitLink, // Splits between HTTP and WebSocket links
  ]);
  // Create Apollo client with combined links and cache
  const cache = new InMemoryCache();
  const client = new ApolloClient({
    cache,
    link,
  });
  return client;
};
