import React, { useState } from 'react';
import TooltipMui, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from './lib';

const Tooltip = ({ children, isActive = true, bgColor, ...props }) => {
  const [open, setOpen] = useState(false);

  // Handle touch events for mobile
  const handleTouchStart = () => {
    setOpen(true);
  };

  const handleTouchEnd = () => {
    setOpen(false);
  };

  // Handle hover events for web
  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  if (!isActive) {
    return children;
  }

  return (
    <span
      onTouchStart={handleTouchStart} // Trigger tooltip on touch for mobile
      onTouchEnd={handleTouchEnd} // Hide tooltip when touch ends for mobile
      onMouseEnter={handleMouseEnter} // Trigger tooltip on hover for web
      onMouseLeave={handleMouseLeave} // Hide tooltip when hover ends for web
    >
      <TooltipMuiStyled {...props} bgColor={bgColor} open={open}>
        <span>{children}</span>
      </TooltipMuiStyled>
    </span>
  );
};

export default Tooltip;

const TooltipMuiStyled = styled(({ className, ...props }) => (
  <TooltipMui {...props} arrow classes={{ popper: className }} />
))(({ bgColor }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: bgColor,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: bgColor,
  },
}));
